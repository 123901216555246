import { Broadcaster } from './../common/providers/broadcaster.service';
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
// import * as _ from "lodash";
import find from "lodash/find";
import pick from "lodash/pick";
import sortBy from "lodash/sortBy";
import { AppService } from "../services/app.service";
import {
  getActiveApps,
  getAllConversation,
  getAppsCount,
  getAppsUnreadCount,
  getContactById,
  getContactState,
  getContactVCardById,
  getConversationById,
  getConversationIds,
  getConversationMembers,
  getConversationMessageState,
  getConversationState,
  getDateRange,
  getFederatedApps,
  getFilters,
  getGroupBy, getHideAllComments,
  getIsActionProcessing,
  getIsConnectedXMPP,
  getIsLoggedIn,
  getLanguage,
  getLastText,
  getMessageById,
  getMessagesByConversationTarget,
  getMessageState,
  getMessageToReply,
  getNotificationSettings,
  getOnlineStatus,
  getOrderBy,
  getProjectData,
  getSearchString,
  getSelectedConversation,
  getSelectedConversationText,
  getShowFilters,
  getTags,
  getTotalPerPage,
  getUnreadOnly,
  getUserProfile,
  getViewBy,
  RootState
} from "../reducers";
import { ElectronService } from "../services/electron.service";
import {
  RestoreSavedState,
  SetActiveApps,
  SetAdvancedSearch,
  SetFluidTotalUnread,
  SetDateRange,
  SetGroupBy,
  SetHideAllComments,
  SetLanguage,
  SetMessageToReply,
  SetNotificationSettings,
  SetOrderBy,
  SetProjectData,
  SetSearchString,
  SetShowFilters,
  SetTags,
  SetTotalPerPage,
  SetUnreadOnly,
  SetViewBy,
  StartProcessing,
  StopProcessing,
  ToggleActiveApp
} from "../actions/app";
import { bufferTime, concatMap, debounceTime, filter, map, reduce, switchMap, take, takeWhile, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "../common/providers/toast.service";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { CommonUtils } from "../common/utils/common-util";
import { CommanderConstants } from "../common/utils/commander.constants";
import { Contact, ContactInformation, ContactRest, Group } from "../common/models/ldap-contact.model";
import { Conversation, RoomMembersOwnerAvatar } from "../common/models/conversation.model";
import { SearchResponse } from "../common/models/mail-models/search-item";
import {
  ContactAdd,
  ContactBulkAdd,
  ContactBulkStatusUpdate,
  ContactLoadRequest,
  ContactStatusUpdate,
  UserStatus
} from "../actions/contact";
import {
    ArchiveConversation,
  ConversationLoadSuccess,
  ConversationSelect,
  ConversationUpdateBroadcastData,
  ConversationUpdateLastActivity,
  MultiConversationUpdateMembers,
  MultiConversationUpdateOwner,
  RemoveUploadedFile,
  RestoreLastText,
  UpdateFileInProgress
} from "../actions/conversation";
import { MessageAdd, MessageBulkAppend, MessageDeleteAction, MessageUpdateAction } from "../actions/message";
import { Message, MessageStatus } from "../common/models/message.model";
import { UserProfile } from "../common/models/mail-models";
import { HttpEventType } from "@angular/common/http";
import { SaveSendMessage } from "src/app/common/models/mail-models/save-send.model";
import { XmppService } from "../services/xmpp.service";
import { GroupAdd, GroupBulkAdd, GroupLoadRequest, GroupLoadSuccess } from "../actions/group";
import { MetaComment } from "../common/models/comment.model";
import { SearchRequest } from "src/app/common/models/mail-models/search-request.model";
import { environment } from "src/environments/environment";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { FeedRequest } from "../common/models/feed-request.model";
import { ConfigService } from "../config.service";
import { TicketAddList } from "../actions/tickets";
import { TaskAddList } from "../actions/task";
import { AIService } from '../services/ai.service';

@Injectable()
export class AppRepository {
    currentUser: UserProfile;
    decimalNcr = {
        ä: "&#228;",
        ö: "&#246;",
        ü: "&#252;",
        Ä: "&#196;",
        Ö: "&#214;",
        Ü: "&#220;",
        ß: "&#223;",
        ẞ: "&#7838;"
    };
    private pendingMessages: Message[] = [];
    isAppOnline: boolean;
    isSending: boolean;
    isInQueueMsgIds: string[] = [];
    sentIds = [];
    isMobileScreen: any;
    talkURL: any;
    fullName: string = "";
    hideCommander: boolean = true;
    hideBriefcase: boolean = true;
    constructor(private appService: AppService,
        private store: Store<RootState>,
        private zone: NgZone,
        private xmppService: XmppService,
        private broadcaster: Broadcaster,
        private translate: TranslateService,
        private toastService: ToastService,
        private breakpointObserver: BreakpointObserver,
        private electronService: ElectronService,
        private aiService: AIService,
        private configService: ConfigService) {
        if (!!localStorage.getItem("notificationSettings")) {
            this.setNotificationSettings(JSON.parse(localStorage.getItem("notificationSettings")));
        }
        this.getFederatedApps().subscribe((apps: any) => {

          const talk = apps.find(app => app.appKey === "talk");
            if (!!talk) {

              this.talkURL = talk.appUrl;
            }
          });
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
        this.breakpointObserver
        .observe(["(max-width: 599px)"])
        .subscribe((state: BreakpointState) => {
            if (state.matches) {
            this.isMobileScreen = true;
            } else {
            this.isMobileScreen = false;
            }
        });
        const pendingMessages = localStorage.getItem("pendingMessages");

        if (pendingMessages) {
            this.pendingMessages = JSON.parse(pendingMessages);

        }

        combineLatest([this.getOnlineStatus(), this.getIsLoggedIn()]).subscribe(([isOnline, isLoggedIn]) => {
            this.isAppOnline = isOnline;

            if (isOnline && isLoggedIn) {
                this.loadContacts();
                this.loadGroups();
                this.isAIAvailable().subscribe(v => {
                    this.aiService.setupAI();
                    console.log("isAIAvailable", v);
                });

                this.sendPendingMessages();
                this.getTags().pipe(take(1)).subscribe(tags => {

                    this.store.dispatch(new SetTags(tags));
                });
                combineLatest([this.getMyProjects(), this.getStatuses(), this.getPriorities()]).subscribe((results) => {

                    const projects = results[0], statuses = results[1], priorities = results[2];
                    this.store.dispatch(new SetProjectData({projects, statuses, priorities}));
                });
            }
        });

        this.getUserProfile().pipe(filter(v => !!v), take(1)).subscribe(profile => {
            this.currentUser = profile;
            this.xmppService.init();
        });

        this.store.select(getUserProfile).pipe().subscribe(res => {
            // console.log(res)
            if (res) {

                this.getContactVCard(res.email).pipe(take(1)).subscribe(data => {
                    let vCard = data || {};
                    if (vCard.fullName) {
                        this.fullName = vCard.fullName;
                    }
                });
            }
        });

    }

    isAIAvailable() {
      return combineLatest([this.appService.loadConfig(), this.getUserProfile()]).pipe(take(1), map(([config, profile]) => {
        console.log("isAIAvailable", config);
        this.configService.set("aiURL", config.aiURL);
        this.configService.set("botJid", config.botJid);
        this.hideBriefcase = config.hideBriefcase;
        this.hideCommander = config.hideCommander;
        this.configService.set("hideCommander", config.hideCommander);
        this.configService.set("hideBriefcase", config.hideBriefcase);
        return !!config.botJid && (config.botJid.indexOf("@") > -1) && (config.botJid.split("@")[1] === profile?.email?.split("@")[1]);
      }));
    }

    public getContactVCard(bareId: string): Observable<ContactInformation> {
        return this.store.select(state => getContactVCardById(state, bareId));
    }

    makeTalkAudioChatVideoOperation(email, action: string, groupName: string): void {
      const emails: any[] = [];
      emails.push(email);
      this.talkCallAudioChatVideoOperation(action, emails.toString(), groupName);
    }

    talkCallAudioChatVideoOperation(action: string, target: string, groupName?: string) {
      let talkAppURL: string = "";
      this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
        talkAppURL = apps.filter( ap => ap.appName.toLowerCase() === "vnctalk")[0]?.appUrl;
      });
      talkAppURL = this.getURL(talkAppURL);
      if (this.electronService.isElectron) {
        try {
          let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://main");
          console.log("appswitcher nativeHandler: ", nativeHandler);
          if (nativeHandler && nativeHandler !== "") {
            talkAppURL = "vnctalk://main/";
          }
        } catch (e) {
          console.error("[common-repository] error: ", e);
        }
      }

      let sendURL;
      if (talkAppURL.endsWith("/")) {
        sendURL = talkAppURL + "talk/trigger?action=" + action + "&target=" + target;
      } else {
        sendURL = talkAppURL + "/talk/trigger?action=" + action + "&target=" + target;
      }
      if (groupName) {
        sendURL += "&groupName=" + groupName;
      }
      if (environment.isCordova) {
        if (device.platform === "iOS") {
          window.open(sendURL, "_system");
        } else if (device.platform === "Android") {
          navigator.app.loadUrl(sendURL, {
            openExternal: true
          });
        }
      } else if (environment.isElectron) {
        this.electronService.openExternalUrl(sendURL);
      } else {
        window.open(sendURL, "_blank");
      }
    }

    public composeEmail(email): void {
      const emails: any[] = [];
      emails.push(email);
      let mailAppURL: string = "";
      this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
          mailAppURL = apps.filter( ap => ap.appName.toLowerCase() === "vncmail")[0]?.appUrl;
      });
      mailAppURL = this.getURL(mailAppURL);
      const sendURL = mailAppURL + "mail/compose?to=" + emails.toString();
      if (environment.isCordova) {
        if (device.platform === "iOS") {
            window.open(sendURL, "_system");
        } else if (device.platform === "Android") {
            navigator.app.loadUrl(sendURL, {
                openExternal: true
            });
        }
      }  else if (environment.isElectron) {
        this.electronService.openExternalUrl(sendURL);
      } else {
        window.open(sendURL, "_blank");
      }
    }

    public messageAction(body: any) {
        return this.appService.messageAction(body);
    }

    public getDashboard() {
        return this.appService.getDashboard();
    }

    public getCapacities() {
        return this.appService.getCapacities();
    }

    public updateCapacities(payload: any) {
        return this.appService.updateCapacities(payload);
    }

    public createWorkspace(payload: any) {
        return this.appService.createWorkspace(payload);
    }

    public getWorkspace(id: any) {
        return this.appService.getWorkspace(id);
    }

    public updateMembers(id: any, payload: any) {
        return this.appService.updateMembers(id, payload);
    }

    public getMembers(id: any) {
        return this.appService.getMembers(id);
    }

    public getEntities(id: any) {
        return this.appService.getEntities(id);
    }

    public updateWorkspace(payload: any) {
        return this.appService.updateWorkspace(payload);
    }

    public archiveWorkspace(id: any) {
        return this.appService.archiveWorkspace(id);
    }

    public unarchiveWorkspace(id: any) {
        return this.appService.unarchiveWorkspace(id);
    }

    public deleteWorkspace(id: any) {
        return this.appService.deleteWorkspace(id);
    }

    public getWorkspaces(query?: any) {
        return this.appService.getWorkspaces(query);
    }

    public deleteCapacity(id: any) {
        return this.appService.deleteCapacity(id);
    }

    public getLastActivity(bare: string): Observable<any> {
        const response = new Subject<any>();
        this.xmppService.getLastActivity(bare).subscribe(res => {

          // eslint-disable-next-line radix
          const lastActivitySeconds = parseInt(res.lastActivity);
          this.store.dispatch(new ConversationUpdateLastActivity({target: bare, seconds: lastActivitySeconds}));
          this.store.dispatch(new ContactStatusUpdate(this.contactStatusUpdateData(bare, lastActivitySeconds)));
          response.next(res.lastActivity);
        }, err => {
          console.error("[ContactRepository][getLastActivity] error", err);
          response.error(err);
        });
        return response.asObservable();
      }

      private getLastActivityBatch(bareJids: string[]): Observable<any> {

        const response = new Subject<any>();
        this.xmppService.getLastActivityBatch(bareJids).subscribe(res => {
          let statusArray = [];

          if (res && res.lastActivityBatchResults) {
            for (let jid of Object.keys(res.lastActivityBatchResults)) {
                const lastActivitySeconds = parseInt(res.lastActivityBatchResults[jid].seconds, 0);
                this.store.dispatch(new ConversationUpdateLastActivity({target: jid, seconds: lastActivitySeconds}));
                statusArray.push(this.contactStatusUpdateData(jid, lastActivitySeconds));
              }
              this.store.dispatch(new ContactBulkStatusUpdate(statusArray));
          }
          response.next(res.lastActivityBatchResults);
        }, err => {
          response.error(err);
        });
        return response.asObservable();
      }

    syncLastActivityOfAllContacts(contacts) {

        if (contacts.length === 0) {
          return;
        }
        return this.store.select(getIsConnectedXMPP).pipe(filter(v => !!v), take(1)).subscribe(() => {
          const contactsBareJids: string[] = contacts.map(c => c.defaultMail);
          this.getLastActivityBatch(contactsBareJids);
        });
    }

    updateContactStatus(jid: string, timestamp: number) {
        const lastActivitySeconds = Math.abs(new Date().getTime() - timestamp) / 1000;
        this.store.dispatch(new ConversationUpdateLastActivity({target: jid, seconds: lastActivitySeconds}));
        this.store.dispatch(new ContactBulkStatusUpdate([this.contactStatusUpdateData(jid, lastActivitySeconds)]));
    }

    private contactStatusUpdateData(jid: string, lastActivitySeconds: number) {
        return {jid: jid,
                status: (lastActivitySeconds > -1 && lastActivitySeconds < 300)
                ? UserStatus.ONLINE : UserStatus.OFFLINE};
    }

    public setupStatePersistance() {
        if (CommonUtils.isOnNativeMobileDevice()) {
            this.store.select(getLastText)
                .pipe(filter(lastText => !!lastText)
                    , bufferTime(1000)
                    , filter(data => data.length > 0 && !!localStorage.getItem("token"))
                    , map(data => data[data.length - 1]))
                .subscribe(lastText => {
                    localStorage.setItem("lastText", JSON.stringify(lastText));
                });

            // Web
        } else {
            const conversationState$ = this.store.select(getConversationState)
                .pipe(map(state => pick(state, ["ids", "entities", "lastText"])), debounceTime(1000));
            const conversationMessageState$ = this.store.select(getConversationMessageState)
                .pipe(map(state => pick(state, ["ids", "unreadCount"])));
            const messageState$ = this.store.select(getMessageState)
                .pipe(map(state => pick(state, ["ids", "entities"])));
            const contactState$ = this.store.select(getContactState)
                .pipe(map(state => pick(state, ["ids", "entities", "photoLastUpdate"])));
            combineLatest([conversationState$, conversationMessageState$, messageState$, contactState$]).pipe(
                map(results => {
                    return {
                        conversationState: results[0],
                        conversationMessageState: results[1],
                        messageState: results[2],
                        contactState: results[3]
                    };
                }),
                bufferTime(1000),
                filter(states => states.length > 0),
                map(states => states[states.length - 1])
            ).subscribe(states => {

                const data = JSON.stringify(states);
                localStorage.setItem("saved_state", data);
            });
        }
    }

    public restoreFromState() {
        if (CommonUtils.isOnNativeMobileDevice()) {
            this.processRestoreStateMobile();
        } else {
            this.processRestoreStateWeb(localStorage.getItem("saved_state"));
        }
    }

    private saveMessagesToStore(target: string, messages: Message[]) {

        const msgInStore = [];
        messages.forEach(message => {
            this.store.select(state => getMessageById(state, message.id)).pipe(take(1)).subscribe(msg => {
                if (!!msg) {
                    this.store.dispatch(new MessageUpdateAction({ id: message.id, changes: message }));
                    msgInStore.push(message.id);
                }
            });
        });

        this.store.dispatch(new MessageBulkAppend({
            messages: messages.filter(m => !msgInStore.includes(m.id)),
            conversationTarget: target
        }));
    }

    private processRestoreStateWeb(state) {
        if (!state) {
            return;
        }
        let savedState;
        try {
            savedState = JSON.parse(state);
        } catch (e) {

            return;
        }

        this.store.dispatch(new RestoreSavedState(savedState));
    }

    private processRestoreStateMobile() {
        const lastText = localStorage.getItem("lastText");

        try {

            if (lastText) {
                this.store.dispatch(new RestoreLastText(JSON.parse(lastText)));
            }
        } catch (e) {
            console.error("[OFFLINE] Invalid JSON", e);
            return;
        }
    }

    underDevelopment() {
        this.showMessage("UNDER_DEVELOPMENT");
    }

    showMessage(translationKey: string, timeout = 1000) {
        this.translate.get(translationKey).pipe(take(1)).subscribe(text => {
            this.toastService.show(text, timeout);
        });
    }

    showPlainMessage(message: string, timeout = 1000) {
            this.toastService.show(message, timeout);
    }

    setViewBy(value: string) {
        this.store.dispatch(new SetViewBy(value));
    }

    getViewBy() {
        return this.store.select(getViewBy);
    }

    getUserProfile() {
        return this.store.select(getUserProfile);
    }

    getOnlineStatus() {
        return this.store.select(getOnlineStatus);
    }

    setTags(value: any[]) {
        this.store.dispatch(new SetTags(value));
    }

    setGroupBy(value: string) {
        this.store.dispatch(new SetGroupBy(value));
    }

    setActiveApps(value: string[]) {
        this.store.dispatch(new SetActiveApps(value));
    }

    getGroupBy() {
        return this.store.select(getGroupBy);
    }

    getOrderBy() {
        return this.store.select(getOrderBy);
    }

    getSearchString() {
        return this.store.select(getSearchString);
    }

    getUnreadOnly() {
        return this.store.select(getUnreadOnly);
    }

    getHideAllComments() {
      return this.store.select(getHideAllComments);
    }

    getIsAppLoading() {
        return this.store.select(getIsActionProcessing);
    }

    getIsLoggedIn() {
        return this.store.select(getIsLoggedIn);
    }

    getActiveApps() {
        return this.store.select(getActiveApps);
    }

    getFederatedApps() {
        return this.store.select(getFederatedApps);
    }

    getNotificationSettings() {
        return this.store.select(getNotificationSettings);
    }

    getLanguage() {
        return this.store.select(getLanguage);
    }

    getTotalPerPage() {
        return this.store.select(getTotalPerPage);
    }

    getDateRange() {
      return this.store.select(getDateRange);
    }

    getShowFilters() {
        return this.store.select(getShowFilters);
    }

    getAppsCount() {
        return this.store.select(getAppsCount);
    }

    getAppsUnreadCount() {
        return this.store.select(getAppsUnreadCount);
    }

    getGlobalTags() {
        return this.store.select(getTags);
    }

    getProjectData() {
        return this.store.select(getProjectData);
    }

    getFilters() {
        return this.store.select(getFilters);
    }

    setFilters(value: any) {
        this.store.dispatch(new SetAdvancedSearch(value));
    }

    setOrderBy(value: string) {
        this.store.dispatch(new SetOrderBy(value));
    }

    setLanguage(value: string) {
        this.store.dispatch(new SetLanguage(value));
    }

    setTotalPerPage(value: number) {
        this.store.dispatch(new SetTotalPerPage(value));
    }

    setDateRange(value: any) {
      this.store.dispatch(new SetDateRange(value));
    }

    setShowFilters(value: boolean) {
        this.store.dispatch(new SetShowFilters(value));
    }

    setNotificationSettings(value: { [app: string]: boolean }) {
        localStorage.setItem("notificationSettings", JSON.stringify(value));
        this.store.dispatch(new SetNotificationSettings(value));
    }

    toggleActiveApp(app: string) {
        this.store.dispatch(new ToggleActiveApp(app));
    }

    setMessageToReply(message: Message) {
        this.store.dispatch(new SetMessageToReply(message));
    }

    getMessageToReply() {
        return this.store.select(getMessageToReply);
    }

    setUnreadOnly(value: boolean) {
        this.store.dispatch(new SetUnreadOnly(value));
    }

    setHideAllComments(value: boolean) {
      this.store.dispatch(new SetHideAllComments(value));
    }

    setSearchString(value: string) {
        this.store.dispatch(new SetSearchString(value));
    }

    getContactByEmail(email: string) {
        return this.store.select(state => getContactById(state, email));
    }

    addContact(contact: Contact) {
        this.store.dispatch(new ContactAdd(contact));
    }

    searchDocs(body: FeedRequest): Observable<SearchResponse> {
        this.store.dispatch(new StartProcessing());
        return this.appService.searchDocs(body).pipe(tap(res => {
            this.store.dispatch(new StopProcessing());
        }));
    }

    searchLDAP(keyword) {
        return this.appService.searchLDAP(keyword);
    }

    sendMail(data) {
        this.appService.sendMail(data).subscribe(res => {

        });
    }

    uploadAvatar(files) {
        this.store.dispatch(new StartProcessing());
        return this.appService.uploadAvatar(files).pipe(tap(res => {
            this.store.dispatch(new StopProcessing());
        }));
    }

    private lastContactsFetchTimeStamp() {
        return localStorage.getItem("lastContactsFetchTimeStamp");
    }

    private setLastContactsFetchTimeStamp() {
        localStorage.setItem("lastContactsFetchTimeStamp", new Date().toISOString());
    }

    private cleanLastContactsFetchTimeStamp() {


        localStorage.removeItem("lastContactsFetchTimeStamp");
    }


    private processAPIContact(rawContact: any): ContactRest {
        let contact: ContactRest = {
            id: rawContact.id,
            created_at: new Date(rawContact.created_at),
            updated_at: new Date(rawContact.updated_at),
            is_company: rawContact.is_company,
            first_name: rawContact.first_name,
            middle_name: rawContact.middle_name,
            last_name: rawContact.last_name,
            company: rawContact.company,
            job_title: rawContact.job_title,
        };
        let name = [];
        if (rawContact.first_name) {
            name.push(rawContact.first_name);
        }
        if (rawContact.deleted_at) {
            name.push(new Date(rawContact.deleted_at));
        }
        if (rawContact.middle_name) {
            name.push(rawContact.middle_name);
        }
        if (rawContact.last_name) {
            name.push(rawContact.last_name);
        }

        contact.name = name.join(" ");

        if (rawContact.phones) {
            contact.phones = rawContact.phones;
        }

        if (rawContact.jid) {
          contact.defaultMail = rawContact.jid;
        } else if (rawContact.email) {
          contact.defaultMail = rawContact.email;
        } else if (rawContact.emails) {
          contact.defaultMail = rawContact.emails[0].email;
        }

        if (rawContact.emails) {
            contact.emails = rawContact.emails;
        }
        if (rawContact.addresses) {
            contact.addresses = rawContact.addresses;
        }

        let oldGroups = rawContact.groups || [];
        let isGeneralAdded = false;

        if (CommonUtils.isNullOrUndefined(oldGroups) || oldGroups.length === 0) {
            oldGroups = [{ id: 0 }];
            isGeneralAdded = true;
        }

        return {
            ...contact,
            groups: oldGroups,
            isGeneralAdded: isGeneralAdded
        };
    }

    private loadContacts() {

        this.store.dispatch(new ContactLoadRequest());
        this.loadUpdatedContacts().subscribe(contacts => {

            contacts = sortBy(contacts, ["name"], ["asc"]);

            this.store.dispatch(new ContactBulkAdd(contacts));
            this.syncLastActivityOfAllContacts(contacts);
        });
    }

    getAllRoomMembers() {
      return this.appService.getAllRoomMembers();
    }

    loadUpdatedContacts(): Observable<any[]> {

        const trigger = new BehaviorSubject<number>(0);
        return trigger.asObservable().pipe(concatMap(offset => {
            const lastContactsFetchTimeStamp = this.lastContactsFetchTimeStamp();
            let params = { offset };
            params["limit"] = CommanderConstants.CONTACTS_REST_PER_PAGE;
            if (lastContactsFetchTimeStamp && CommonUtils.isOnNativeMobileDevice()) {
                params["updated_after"] = lastContactsFetchTimeStamp;
            }
            return this.appService.getContacts(params)
                .pipe(map(res => {
                    if (!res.contacts) {
                        res = JSON.parse(res);
                    }
                    let contacts = res.contacts.map(v => this.processAPIContact(v));

                    offset = offset + CommanderConstants.CONTACTS_REST_PER_PAGE;
                    if (res.total_count > offset) {
                        trigger.next(offset);
                    } else {
                        // save updated_after
                        this.setLastContactsFetchTimeStamp();

                        trigger.complete();
                    }
                    return contacts;
                }));
        }), takeWhile(contacts => contacts.length > 0)
            , reduce((accumulator, contacts) => {
                return [...accumulator, ...contacts];
            }, []));
    }

    private loadGroups() {

        this.store.dispatch(new GroupLoadRequest());
        this.loadAllGroups().subscribe(groups => {
          groups = sortBy(groups, ["name"], ["asc"]);
          if (!find(groups, {name: CommanderConstants.FAVOURITE_LIST_NAME})) {
            this.appService.createGroup(CommanderConstants.FAVOURITE_LIST_NAME).subscribe((res: any) => {
              this.store.dispatch(new GroupAdd(res.contact_group as Group));
            });
          }

          this.store.dispatch(new GroupLoadSuccess(groups));
          this.store.dispatch(new GroupBulkAdd(groups));
        });
      }

    private loadAllGroups(): Observable<Group[]> {
        const trigger = new BehaviorSubject<number>(0);
        return trigger.asObservable().pipe(concatMap(offset =>
            this.appService.getGroups({ offset })
                .pipe(map(res => {
                    let groups = res.contact_groups.map(v => v as Group);
                    if (res.total_count > 25) {
                        offset = offset + 25;
                        trigger.next(offset);
                    } else {
                        trigger.complete();
                    }
                    return groups;
                }))
        ), takeWhile(groups => groups.length > 0)
            , reduce((accumulator, groups) => {
                return [...accumulator, ...groups];
            }, []));
    }

    getConversations(offset: number, limit: number = 100,
        updated_after?: number): Observable<{ eod: boolean, conversations: Conversation[] }> {
        return this.appService.getConvHistory(offset, limit, updated_after).pipe(map(res => {
            if (!res.conversations) {
                res = JSON.parse(res);
            }
            return {
                conversations: res.conversations.map(conv => {
                    conv.avatarUrl = this.buildAvatarURL(conv.Target);
                    conv.isfav = conv.isfav;
                    return CommonUtils.mapMiddleware(conv);
                }),
                eod: res.eod
            };
        }));
    }

    getContact(contactId: number) {
        return this.appService.getContact(contactId);
    }

    loadMessages(target: string, maxItems: number, offset: number = 0, startTimestamp?: number, endTimestamp?: number) {
        return this.appService.loadMessages(target, maxItems, offset, startTimestamp, endTimestamp).pipe(tap(res => {
            this.saveMessagesToStore(target, res.messages);
        }));
    }

    loadAllConversationsFromStore(): Observable<Conversation[]> {
        return this.store.select(getAllConversation);
    }

    loadAllConversations(): Observable<Conversation[]> {
        const trigger = new BehaviorSubject<number>(0);
        let timestamp;
        if (localStorage.getItem("lastPollingTimeStamp")) {
            timestamp = +localStorage.getItem("lastPollingTimeStamp");
        }
        return trigger.pipe(concatMap(previousPageNum =>
            this.getConversations(previousPageNum * 100, 200, timestamp)
                .pipe(tap(res => {

                    if (!res.eod) {
                        trigger.next(previousPageNum + 1);
                    } else {
                        trigger.complete();
                    }
                }), map(res => {
                    const allConversations = res.conversations as Conversation[];
                    let conversations = allConversations.filter(c => !c.deleted);
                    return conversations;
                }, err => {

                }), reduce((accumulator, conversations) => [...accumulator, ...conversations], []))
        ), tap(res => {
            this.store.dispatch(new ConversationLoadSuccess(res));
        })
        );
    }



    buildAvatarURL(bare) {
        let avatarId = "";
        if (environment.isElectron) {
        avatarId = this.electronService.md5(bare);
        } else {
        avatarId = md5(bare);
        }
        // console.log("avatarId",avatarId);

        if (this.configService.get("avatarURL")) {
        // console.log("avatarurl",`${this.configService.get("avatarURL")}/${avatarId}.jpg`);
        return `${this.configService.get("avatarURL")}/${avatarId}.jpg`;
        }
    }

    getAllRoomMembersAndStore(): void {
        this.appService.getAllRoomMembers().pipe(map(res => {
            if (!!res) {
                let data: RoomMembersOwnerAvatar[] = res.map(v => {
                    let obj = {
                        room: v.room,
                        members: v.members,
                        avatarid: v.avatarid,
                        owner: v.owner
                    };
                    return obj;
                });
                // save members & owner to redux
                //
                let membersJoint = data.map(v => {
                    const obj = { conversationTarget: v.room, members: v.members };
                    return obj;
                });
                this.store.dispatch(new MultiConversationUpdateMembers(membersJoint));
                let ownersJoint = data.map(v => {
                    const obj = { conversationTarget: v.room, owner: v.owner };
                    return obj;
                });
                this.store.dispatch(new MultiConversationUpdateOwner(ownersJoint));
                return data;
            }
            return [];
        })).subscribe(roomMembersAndAvatars => {

        });
    }

    getConversationMembers(target: string): Observable<string[]> {
        return this.store.select(state => getConversationMembers(state, target));
    }

    getRoomMembers(target: string): Observable<any[]> {
        return this.appService.getRoomMembers(target);
    }

    sendMessage(data) {
        this.addToPendingMessage(data);
        return this.appService.sendMessage(data);
    }

    sendMessageOnly(data) {
        return this.appService.sendMessage(data);
    }

    getMailFolders(view?: string) {
        return this.appService.getMailFolders(view);
    }

    getAttributes(section: string) {
        return this.appService.getAttributes(section);
    }

    searchRequest(body: SearchRequest) {
        return this.appService.searchRequest(body);
    }

    saveMailAsDraft(body: SaveSendMessage) {
        return this.appService.saveMailAsDraft(body);
    }

    uploadAttachment(file: any) {
        return this.appService.uploadAttachment(file);
    }

    sendEmail(saveSendMessage: SaveSendMessage) {
        return this.appService.sendEmail(saveSendMessage);
    }

    getAutoCompleteList(inputValue?: string) {
        return this.appService.getAutoCompleteList(inputValue);
    }

    getMsgRequest(id?: string) {
        return this.appService.getMsgRequest(id);
    }

    getSelectedConversationText(target: string): Observable<string> {
        return this.store.select(state => getSelectedConversationText(state, target)).pipe(map(text => {
            return text;
        }));
    }

    getMessagesByConversationTarget(target: string): Observable<Message[]> {
        return this.store.select(state => getMessagesByConversationTarget(state, target));
    }

    getConversationById(target: string): Observable<Conversation> {
        return this.store.select(state => getConversationById(state, target));
    }

    updateBroadcast(target: string, changes: any): void {
        this.store.dispatch(new ConversationUpdateBroadcastData({ conversationTarget: target, changes: changes }));
    }

    getSelectedConversation(): Observable<Conversation> {
        return this.store.select(getSelectedConversation);
    }

    selectConversation(target: string): void {
        this.store.dispatch(new ConversationSelect(target));
    }

    public getAudienceList(broadcastId: string): Observable<any> {
        return this.appService.getAudienceList(broadcastId);
    }

    public forwardMessage(target: string, message: Message): void {

        const chatType = target.indexOf("@conference.") !== -1 ? "groupchat" : "chat";
        let from = message.from;
        if (message.to.startsWith("broadcast-")) {
            from = message.to;
        }
        let storeMessage: Message = {
            id: CommonUtils.randomId(10),
            from: this.currentUser.defaultMail,
            to: target,
            body: message.body,
            status: MessageStatus.PENDING,
            timestamp: new Date().getTime(),
            type: chatType,
            forwardMessage: {
                id: message.id,
                from: from,
                timestamp: message.timestamp
            },
            isForwarded: true
        };
        this.store.dispatch(new MessageAdd({ conversationTarget: target, message: storeMessage }));
        let messageToSend = {
            target: target,
            messagetext: message.body,
            forwardMessage: {
                "@xmlns": "xmpp:vnctalk",
                id: message.id,
                from: from,
                timestamp: message.timestamp
            }
        };
        this.sendMessage(messageToSend).subscribe(res => {
            this.updateSentMessage(target, storeMessage, res);
        });
    }

    public sendFile(file: any, fileName: string, fileSize: number, target?: string, fileType?: string): void {

        let sentURL;
        try {
            sentURL = URL.createObjectURL(file); // File
        } catch (e) {
            console.error("[attachFile] URL.createObjectURL(file) did not work on this platform: " + e);
            return;
        }

        const fType = fileType || fileName.split(".").pop().toLowerCase();
        if (CommonUtils.isImage(fType)) {
            if (!fileName.split(".")[0].trim()) {
                fileName = "photo_" + CommonUtils.randomId(5) + "." + fType;
            }
        }
        const chatType = target.indexOf("@conference.") !== -1 ? "groupchat" : "chat";
        let message: Message = {
            id: CommonUtils.randomId(10),
            from: this.currentUser.defaultMail,
            to: target,
            body: sentURL,
            status: MessageStatus.PENDING,
            timestamp: new Date().getTime(),
            type: chatType,
            attachment: {
                url: sentURL,
                fileSize: fileSize,
                fileName: fileName,
                fileType: fType
            },
            isForwarded: null
        };
        this.store.dispatch(new MessageAdd({ conversationTarget: target, message: message }));
        this.appService.getUploadUrl(fileName, fileSize).pipe(switchMap(url => {
            return this.appService.uploadFile(url, file).pipe(map(event => {
                const fileUrl = url.split("?v=")[0];
                message = {
                    ...message, ...{
                        uploadFileInProgress: true,
                        body: fileUrl,
                        attachment: { ...message.attachment, ...{ url: fileUrl } },
                    }
                };
                return event as any; // TypeScript BS.
            }));
        })).subscribe(res => {

            switch (res.type) {
                case HttpEventType.Sent: // 0
                    message = {
                        ...message, ...{
                            uploadFileInProgress: true
                        }
                    };
                    break;
                case HttpEventType.UploadProgress: // 1
                    message = {
                        ...message, ...{
                            uploadFileInProgress: true
                        }
                    };
                    // show upload progess for Audio attachment
                    if (CommonUtils.isAudio(message.attachment.fileType)) {
                        const total = res.total;
                        const loaded = res.loaded;
                        this.store.dispatch(new UpdateFileInProgress({ messageId: message.id, progress: { total, loaded } }));
                    }
                    break;
                case HttpEventType.ResponseHeader: // 2
                    message = {
                        ...message, ...{
                            uploadFileInProgress: true
                        }
                    };
                    break;
                case HttpEventType.DownloadProgress: // 2
                    break;
                case HttpEventType.Response: // 4
                    message = {
                        ...message, ...{
                            uploadFileInProgress: false
                        }
                    };
                    this.store.dispatch(new RemoveUploadedFile({ messageId: message.id }));
                    message.messagetext = message.body;
                    message.target = message.to;
                    this.sendMessageOnly(message).subscribe(msgId => {
                        if (typeof msgId === "string") {
                            this.updateSentMessage(message.to, message, msgId);
                        } else {
                            this.updateSentMessage(message.to, message, message.id);
                        }
                    });
                    break;
                default:
                    break;
            }
        });


    }

    updateSentMessage(target: string, message: Message, messageId: string) {
        const newMsg = { ...message, ...{ status: MessageStatus.SENT, id: messageId } };
        this.store.dispatch(new MessageDeleteAction(message.id));
        this.removeMessageFromPending(message.id);
        this.store.dispatch(new MessageAdd({ conversationTarget: target, message: newMsg }));
    }

    public renderMentionUsers(content: string, type: string = "html", references?: any[],
        highlightedBare?: string, addHightLight?: boolean): string {
        let mentions = CommonUtils.parseMentions(content);
        if (!!references) {
            // console.log("renderMentionUsers",references)
            const members = references.filter(v => !!v).map(u => {
                if (u.uri) {
                    return u.uri.replace(/xmpp:+/ig, "");
                }
                return u.replace(/xmpp:+/ig, "");
            });
            mentions = mentions.filter(v => members.includes(v));
        }
        if (mentions.length > 0) {
            mentions.forEach(mention => {
                let query = new RegExp(`(@${mention})\s*`, "gim");
                const isHighlighted = highlightedBare && highlightedBare === mention;
                if (this.currentUser && this.currentUser.defaultMail === mention) {
                    content = this.replaceName(type, content, query, this.currentUser.fullName,
                        isHighlighted, addHightLight ? highlightedBare : "");
                } else {
                    this.getContactByEmail(mention).pipe(take(1)).subscribe(contact => {
                        if (!!contact) {
                            const name = contact.name;
                            content = this.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
                        } else {
                            const name = mention.split("@")[0];
                            content = this.replaceName(type, content, query, name, isHighlighted, addHightLight ? highlightedBare : "");
                        }
                    });
                }
            });
        }
        return content;
    }

    private replaceName(type, content, query, name, isHighlighted?: boolean, keyword?: string): string {
        if (type === "plain") {
            content = content.replace(query, `@${name}`);
        } else {
            const className = isHighlighted ? "highlighted" : "";
            if (keyword) {
                name = this.highlightSearch(name, keyword);
            }
            content = content.replace(query, `<span class="mentioned-user ${className}">@${name}</span>`);
        }
        return content;
    }

    highlightSearch(text, keyword) {
        if (!keyword) {
            return text;
        }
        text = text.replace(/&amp;/g, "&")
            .replace(/&lt/g, "<;")
            .replace(/&gt/g, ">;")
            .replace(/&#34;/g, "\"");
        if (keyword.includes("ä" || "ö" || "ü" || "ß" || "Ä" || "Ö" || "Ü" || "ẞ")) {
            keyword = this.convertToNcr(keyword);
        }
        let newText = text;
        const query = new RegExp(this.toUnicode(keyword), "gim");
        if (text.indexOf("href=") !== -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, "text/html");
            for (const a of Array.from(doc.querySelectorAll("a"))) {
                let innerText = a.innerText;
                innerText = innerText.replace(/(<span>|<\/span>)/igm, "").replace(query, "<span class=\"highlight\">$&</span>");
                a.innerHTML = innerText;
            }
            newText = doc.querySelector("body").innerHTML;
        } else {
            let query = new RegExp(this.toUnicode(keyword), "gim");
            newText = newText.replace(query, "<span class=\"highlight\">$&</span>");
        }
        return newText;
    }

    toUnicode(searchKeyword) {
        let unicodeString = "";
        for (let i = 0; i < searchKeyword.length; i++) {
            let theUnicode = searchKeyword.charCodeAt(i).toString(16).toUpperCase();
            while (theUnicode.length < 4) {
                theUnicode = "0" + theUnicode;
            }
            theUnicode = "\\u" + theUnicode;
            unicodeString += theUnicode;
        }
        return unicodeString;
    }

    convertToNcr(searchKeyword) {
        let ncrKeyword = "";
        let singleChar;
        for (let i = 0; i < searchKeyword.length; i++) {
            singleChar = searchKeyword.charAt(i);
            if (this.decimalNcr[singleChar]) {
                ncrKeyword += this.decimalNcr[singleChar];
            } else {
                ncrKeyword += singleChar;
            }
        }
        return ncrKeyword;
    }

    getDisplayName(target): string {
        if (!target) {
            return "";
        }
        let displayName = target.split("@")[0];
        this.getContactByEmail(target)
            .pipe(take(1)).subscribe(res => {
                if (!!res && res.name) {
                    displayName = res.name;
                }
            });
        if (target.startsWith("broadcast-")) {
            this.getConversationById(target)
                .pipe(take(1)).subscribe(res => {
                    if (!!res && res.broadcast_title) {
                        displayName = res.broadcast_title;
                    }
                });
        }
        return displayName;
    }

    addToPendingMessage(message) {
        if (!find(this.pendingMessages, { id: message.id })) {

            this.pendingMessages.push(message);
            localStorage.setItem("pendingMessages", JSON.stringify(this.pendingMessages));
            this.sendPendingMessages();
        }
    }

    sendPendingMessages() {
        if (!!localStorage.getItem("pendingMessages")) {
            this.pendingMessages = JSON.parse(localStorage.getItem("pendingMessages"));
        }
        if (!this.isAppOnline || this.pendingMessages.length === 0 || this.isSending) {
            return;
        }

        let convTargets: string[];
        this.store.select(getConversationIds).pipe(take(1)).subscribe(ids => convTargets = ids as string[]);
        this.isSending = true;
        const messages = this.pendingMessages;
        messages.forEach((msg: Message) => {
            if (!this.isInQueueMsgIds.includes(msg.id)) {
                this.isInQueueMsgIds.push(msg.id);
                const message = msg.data ? msg.data : msg;
                this.sendMessage(message).subscribe(msgId => {

                    const target = message.target;
                    delete msg.data;
                    this.updateSentMessage(target, msg, msgId);
                }, err => {
                    this.isInQueueMsgIds = this.isInQueueMsgIds.filter(v => v !== msg.id);
                });
            }
            // // Attachment
            // if (msg.file) {
            //     this.aboutToSendAttachment(msg, convTargets);
            //     // Text
            // } else {
            //     this.store.dispatch(new MessageDeleteAction(msg.id));
            // }
        });
        this.isSending = false;
    }

    private aboutToSendAttachment(msg: Message, convTargets: string[]) {

        this.updateFileInProgress(msg.id, true);
        this.uploadAttachmentAndSend(msg, convTargets);
    }

    private removeMessageFromPending(mId) {

        this.pendingMessages = CommonUtils.removeMessageFromPending(mId);
    }

    private updateFileInProgress(msgId: string, value: boolean): void {
        this.pendingMessages.filter(v => v.id === msgId).forEach(m => {
            m.uploadFileInProgress = value;
        });
        this.store.dispatch(new MessageUpdateAction({ id: msgId, changes: { uploadFileInProgress: true } }));
    }

    private uploadAttachmentAndSend(msg: Message, convTargets: string[]) {

    }

    public getMyProducts(): Observable<any> {
        return this.appService.getMyProducts();
    }

    public getLoggedInUserInfo(): Observable<any> {
        return this.appService.getLoggedInUserInfo();
    }

    public getMyProjects(): Observable<any> {
        return this.appService.getMyProjects().pipe(map(v => {
            if (v && v.projects) {
                localStorage.setItem("projects", JSON.stringify(v.projects));
            }
            return v && v.projects ? v.projects : [];
        }));
    }

    public getMyProjectDetails(id: number): Observable<any> {
      return this.appService.getMyProjectDetails(id).pipe(map(v => {
        return v.project;
      }));
    }

    public getTaskList(trackerId) {
        return this.appService.getTaskList(trackerId).pipe(map(v => {
            console.log("app.repo-getTaskList v: "),
            this.store.dispatch(new TaskAddList(v.issues));
            return v.issues;
        }));
    }

    public getTracker() {
        return this.appService.getTracker().pipe(map(v => {
            return v;
        }));
    }

  public getStatuses(): Observable<any> {
        return this.appService.getStatuses().pipe(map(v => {
            if (v && v.issue_statuses) {
                localStorage.setItem("issue_statuses", JSON.stringify(v.issue_statuses));
            }
            return v && v.issue_statuses ? v.issue_statuses : [];
        }));
    }

    public getPriorities(): Observable<any> {
        return this.appService.getPriorities().pipe(map(v => {
            if (v && v.issue_priorities) {
                localStorage.setItem("issue_priority", JSON.stringify({
                    "issue_priorities": v.issue_priorities,
                    "expiry": new Date().setDate(new Date().getDate() + 1)
                }));
            }
            return v && v.issue_priorities ? v.issue_priorities : [];
        }));
    }

    setTotalTickets(count) {
        this.store.dispatch(new SetFluidTotalUnread({ totalTasks: count }));
    }

    updateGroupAvatar(target: string, base64Data: string) {
        return this.appService.updateGroupAvatar(target, base64Data);
    }

    getTagsByJid(jid) {
        return this.appService.getTagsByJid(jid).pipe(map(res => {
            return res.objects || [];
        }));
    }

    createOrUpdateTag(jid, tags) {
        return this.appService.createOrUpdateTag(jid, tags).pipe(map((res: any) => {
            return res.objects || [];
        }));
    }

    updateGroupInfo(target, params) {
        return this.appService.updateGroupInfo(target, params).pipe(map((res: any) => {
            return res.objects || [];
        }));
    }

    getTags(params?: any) {
        return this.appService.getTags(params).pipe(map(res => {
            return res.tags || [];
        }));
    }

    getAroundMessages(params?: any) {
        return this.appService.getAroundMessages(params).pipe(map(res => {
            if (res.docs) {
                let messages: Message[] = res.docs.map(message => CommonUtils.convertToXmppMessage(message));
                return messages;
            } else {
                return [];
            }
        }));
    }

    getComments(params?: any): Observable<MetaComment[]> {
        return this.appService.getComments(this.buildCommentParams(params)).pipe(map((res: any) => {
            return (res.comments || []) as MetaComment[];
        }));
    }

    addComment(params: any, text: string, parentId?: number): Observable<MetaComment> {
        return this.appService.addComment(this.buildCommentParams(params), text, parentId).pipe(map((res: any) => {
            if (res && res.comment) {
                return res.comment as MetaComment;
            }
            return null;
        }));
    }

    private buildCommentParams(params) {
        switch (params.product) {
            case "mail": params.object_type = "email"; break;
            case "talk": params.object_type = "message"; break;
            case "task": params.object_type = "task"; break;
        }
        params.product = `vnc${params.product}`;
        return params;
    }

    public getProjectMemberList(projectId: number) {
      return this.appService.getProjectMembers(projectId);
    }

    public addNewTask(payload: any) {
      payload.tracker_id = localStorage.getItem("tracker_id");
      if (payload.tags) {
        payload.tags = payload.tags.map(t => t.name).join(",");
      }
      payload.watcher_user_ids = [];

      if (payload.repeats) {
        payload.recurring_tasks_attributes = {
          "0": {
            "interval_number": "1",
            "interval_unit": payload.repeats,
            "interval_modifier": "mdff",
            "fixed_schedule": "1"
          }
        };
        delete payload.repeats;
      }

      return this.appService.addNewTask(payload);
    }

    public editTask(taskId: number,  payload: any) {
      payload.tracker_id = localStorage.getItem("tracker_id");
      if (payload.tags) {
        payload.tags = payload.tags.map(t => t.name).join(",");
      }
      payload.watcher_user_ids = [];

      if (payload.repeats) {
        payload.recurring_tasks_attributes = {
          "0": {
            "interval_number": "1",
            "interval_unit": payload.repeats,
            "interval_modifier": "mdff",
            "fixed_schedule": "1"
          }
        };
        delete payload.repeats;
      }

      return this.appService.editTask(taskId, payload);
    }

    public getTaskDetails(taskId: number): Observable<any> {
      return this.appService.getTaskDetails(taskId);
      }

    changeTaskCompletedStatus(taskId: number, completed: boolean) {
      let redmineStatus = {new: 1, inProgress: 2, completed: 18};
      let payload: any  = {
        status_id: completed ? redmineStatus.completed : redmineStatus.inProgress
      };
      return this.appService.editTask(taskId, payload);
    }

    deleteTask(taskId: number) {
        return this.appService.deleteTask(taskId);
    }

    getSavedSearches() {
      return this.appService.getSavedSearches();
    }

    addSearch(body: any) {
      return this.appService.addSearch(body);
    }

    updatedSearch(body: any) {
      return this.appService.updatedSearch(body);
    }

    deleteSearch(searchId: any) {
      this.appService.deleteSearch(searchId);
    }

    public getTicketList(): Observable<any> {
        return this.appService.getTicketList().pipe(map(v => {
            this.store.dispatch(new TicketAddList(v.issues));
            return v.issues;
        }));
    }

    public getTicketPreview(issueID): Observable<any> {
        return this.appService.getTicketPreview(issueID);
    }


    public getCurrentTicketUser(): Observable<any> {
        return this.appService.getCurrentTicketUser().pipe(map(user => {
            return user;
        }));
    }

    public addWatcherInTicket(ticketId, Payload): Observable<any> {
        return this.appService.addWatcherInTicket(ticketId, Payload).pipe(map(response => {
            return response;
        }));
    }

    public removeWatcherFromTicket(ticketId, Payload): Observable<any> {
        return this.appService.removeWatcherInTicket(ticketId, Payload).pipe(map(response => {
            return response;
        }));
    }

    public addNewTicket(payload: any) {
      payload.tracker_id = localStorage.getItem("tracker_id");
      if (payload.tags) {
        payload.tags = payload.tags.map(t => t.name).join(",");
      }

      return this.appService.addNewTicket(payload);
    }

    public editTicket(id: number,  payload: any) {
      payload.tracker_id = localStorage.getItem("tracker_id");
      if (payload.tags) {
        payload.tags = payload.tags.map(t => t.name).join(",");
      }
      return this.appService.editTicket(id, payload);
    }

    public getTicketDetails(id: number): Observable<any> {
      return this.appService.getTicketDetails(id);
    }

    public getUnreadMessage(): Observable<any> {
      return this.appService.getUnreadMessageFromConversation();
    }

    public getUnreadMail(): Observable<any> {
        const response = new Subject<any>();
        this.appService.getMailFolders().pipe(take(1)).subscribe(folders => {
            // console.log("MAILFOLDERSUNREAD folders: ", folders.folder);
            if (!!folders && !!folders.folder && !!folders.folder[0] && !!folders.folder[0].folder) {
                const inbox = folders.folder[0].folder.find(f => f.id === "2");
                // console.log("MAILFOLDERSUNREAD inbox: ", inbox);
                response.next(inbox);
            } else {
                response.next(null);
            }
        }, err => {
            response.error(err);
        });

        return response.asObservable().pipe(take(1));
    }

    public getTotalMeetings(): Observable<any> {
      return this.appService.getTotalMeetings();
    }

    public addNewIncident(payload: any) {
      payload.tracker_id = localStorage.getItem("tracker_id");
      if (payload.tags) {
        payload.tags = payload.tags.map(t => t.name).join(",");
      }

      return this.appService.addNewIncident(payload);
    }

    public editIncident(id: number,  payload: any) {
      payload.tracker_id = localStorage.getItem("tracker_id");
      if (payload.tags) {
        payload.tags = payload.tags.map(t => t.name).join(",");
      }
      return this.appService.editIncident(id, payload);
    }

    public getIncidentDetails(id: number): Observable<any> {
      return this.appService.getIncidentDetails(id);
    }

    sendNewEmail(email): void {
        const emails: any[] = [];
        emails.push(email);
        let mailAppURL: string = "";
        this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
            mailAppURL = apps.filter( ap => ap.appName.toLowerCase() === "vncmail")[0].appUrl;
        });
        mailAppURL = this.getURL(mailAppURL);
        const sendURL = mailAppURL + "mail/compose?to=" + emails.toString();
        if (environment.isCordova) {
            if (device.platform === "iOS") {
                window.open(sendURL, "_system");
            } else if (device.platform === "Android") {
                navigator.app.loadUrl(sendURL, {
                    openExternal: true
                });
            }
        } else if (environment.isElectron) {
            this.electronService.openExternalUrl(sendURL);
        } else {
            window.open(sendURL, "_blank");
        }
    }

    createTask(email): void {
        let taskAppURL: string = "";
        this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
          taskAppURL = apps.filter( ap => ap.appName.toLowerCase() === "vnctask")[0].appUrl;
        });
        taskAppURL = this.getURL(taskAppURL);
        const sendURL = taskAppURL + "task/open?action=compose&assignee=" + email;
        if (environment.isCordova) {
          if (device.platform === "iOS") {
            window.open(sendURL, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(sendURL, {
              openExternal: true
            });
          }
        } else if (environment.isElectron) {
          this.electronService.openExternalUrl(sendURL);
        } else {
          window.open(sendURL, "_blank");
        }
    }

    createTicket(email): void {
        let projectAppURL: string = "";
        this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
          projectAppURL = apps.filter( ap => ap.appName.toLowerCase() === "vncproject")[0].appUrl;
        });
        projectAppURL = this.getURL(projectAppURL);
        const sendURL = projectAppURL + "issues/new?assignee_username=" + email;
        if (environment.isCordova) {
          if (device.platform === "iOS") {
            window.open(sendURL, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(sendURL, {
              openExternal: true
            });
          }
        } else if (environment.isElectron) {
          this.electronService.openExternalUrl(sendURL);
        } else {
          window.open(sendURL, "_blank");
        }
    }

    getURL(url: string): string {
        let sendURL: string = "";
        if (url.endsWith("/")) {
            sendURL = url;
        } else {
            sendURL = url + "/";
        }
        return sendURL;
    }

    getSuggestions(query: string) {
      return this.appService.getSuggestions(query);
    }

    getFilteredVNCDTags(params: {name: string}): Observable<any> {
      return  this.appService.getFilteredVNCDTags(params);
    }

    createNewVNCDTag(tagName: string, tagColor: string) {
      return this.appService.createNewVNCDTag(tagName, tagColor);
    }

    updateTagsInPost(postId: string, tags: string[]) {
      return this.appService.updateTagsInPost(postId, tags);
    }

    getMissingGermanKeys() {
      let missingKeys: string[] = [];
      let englishKeys = [];
      let germanKeys = [];

      combineLatest([this.appService.getEnglishJson(), this.appService.getGermanJson()])
        .subscribe(([english, german]) => {
          englishKeys = Object.keys(english);
          germanKeys = Object.keys(german);
          missingKeys = englishKeys.filter( k => !germanKeys.includes(k));

      });
    }

    saveFCMToken(token: string) {
        let email = "";
        this.getUserProfile().pipe(take(1)).subscribe(
          user => {
            email = user?.email;
          }
        );

        if (!!email && !!token) {
          this.appService.saveFCMToken(token, email).subscribe(
            res => {
              console.log("saveFCMToken", res);
            }
          );
        }
    }

    getMailData() {
        return this.appService.getMailData();
    }

    deleteMail(id,l): Observable<any>{
        return this.appService.deleteMail(id,l);
    }

    markUnread(id, operation): Observable<any> {
        return this.appService.markUnreadMail(id, operation);
    }

    markFavoriteMail(id,operation): Observable<any> {
        return this.appService.markFavoriteMail(id,operation);
    }

    markSpamMail(id): Observable<any> {
        return this.appService.markSpamMail(id);
    }

    getMailContent(id): Observable<any> {
        return this.appService.getMailContent(id);
    }

    comingSoon() {
        this.showMessage("COMING_SOON_LBL");
    }

    archiveConversation(conversation: any): Observable<boolean> {
        const response = new Subject<boolean>();
        if (!this.isAppOnline) {
        return of(null);
        }
        this.appService.archiveConversation(conversation).subscribe((conv) => {
        response.next(true);
        this.store.dispatch(new ArchiveConversation({
            target: conversation.Target,
            timestamp: conv.Timestamp,
        }));

        // sync with other client
        const signal = {
            type: "conv-archive",
            target: conversation.Target
        };
        this.xmppService.sendSignalToMyself(signal);
        });

        return response.asObservable();
    }

    toggleConversationFavorite(conversation: any, flag?: boolean, fromSignal = false): Observable<boolean> {
        const response = new Subject<boolean>();
        if (!this.isAppOnline) {
        return of(null);
        }
        this.appService.toggleConversationFavorite(conversation, flag).subscribe((conv) => {
        response.next(true);
        });

        return response.asObservable();
    }

    getEventList() {
        return this.appService.getEventList();
    }

    public getFullName(bare: string, returnYouForLoggedInUser: boolean = false) {
    if (!bare) {
      return "";
    }

    let conversation: Conversation;
    this.store.select((state) => getConversationById(state, bare)).pipe(take(1)).subscribe(c => conversation = c);

    let loggedInJID: any;
    this.store.select(getUserProfile).pipe(take(1)).subscribe(j => loggedInJID = j?.email);

    if (loggedInJID && (bare === loggedInJID.bare) && returnYouForLoggedInUser) {
      return "You";
    }

    if (loggedInJID && (bare === loggedInJID.bare) && !!this.fullName && (this.fullName !== "")) {
      return this.fullName;
    }

    if (conversation && CommonUtils.isGroupChat(conversation)) {
      return conversation.groupChatTitle;
    }
    if (conversation && conversation.Target.startsWith("broadcast-")) {
      return conversation.broadcast_title;
    }

    let contact: any;
    this.getContactById(bare).pipe(take(1)).subscribe(c => {
      contact = c;
    });

    if (contact) {
      if (!!contact.first_name && !!contact.middle_name && !!contact.last_name) {
        return contact.first_name + " " + contact.middle_name + " " + contact.last_name;
      }
      if (!!contact.first_name && !!contact.last_name) {
        return contact.first_name + " " + contact.last_name;
      }

      return contact.name || contact.local;
    }

    return CommonUtils.beautifyName(bare.split("@")[0]);
  }

    public getContactById(bare: string): Observable<Contact> {
      return this.store.select(state => getContactById(state, bare));
    }

    saveFluidSettings(data: any) {
        return this.appService.saveFluidSettings(data).subscribe(x => {
        });
    }

    getFluidSettings() {
        return this.appService.getFluidSettings().subscribe(res => {
            if (res) {
                res = JSON.parse(res);
                this.configService.fieldsInSideBar.pipe(take(1)).subscribe((apps) => {
                    apps.forEach(app => {
                        if (app.appName in res) {
                            app.isChecked = res[app.appName];
                        }
                    });
                    this.configService.fieldsInSideBar.next(apps);
                })
            }
        });
    }

    sendInviteReply(body: any) {
      return this.appService.sendInviteReply(body);
    }

    searchLdap(body: any) {
      return this.appService.searchLdap(body).pipe(map(users => users.map(user => this.mapLDAPUser(user))));
    }

    private getDisplayNameFromLDAPEntry(user: any) {
      if (user.displayName && user.displayName.length > 0) {
        return user.displayName[0];
      }

      if (user.givenName && user.givenName.length > 0) {
        if (user.sn && user.sn.length > 0) {
          return user.givenName[0] + " " + user.sn[0];
        }

        return user.givenName[0];
      }

      if (user.sn && user.sn.length > 0) {
        return user.sn[0];
      }
      return "";
    }

    private mapLDAPUser(user) {
      const jid = user.mail ? user.mail[0] : "";
      const email = user.email ? user.email[0] : jid;
      const displayName = this.getDisplayNameFromLDAPEntry(user);
      return {
        name: displayName,
        jid,
        email,
        ldapData: user
      };
    }

    getProfile(user) {
      const jid = !!user.mail ? user.mail[0] : user.jid;
      const email = user.email ? user.email[0] : jid;
      const displayName = this.getDisplayNameFromLDAPEntry(user);
      const full = displayName.split(" ");
      const first_name = full.pop();
      const last_name = full.join(" ");
      return {
        name: displayName,
        first_name,
        last_name,
        jid,
        email
      };
    }

    public updateTicket(data, issue_id): Observable<any> {
        return this.appService.updateTicketData(data, issue_id).pipe(map(response => {
            return response;
        }));
    }
}
