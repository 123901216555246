/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { RootState } from "../reducers";
import { ConfigService } from "../config.service";

@Injectable()
export class AIService {
  stopStreamer: boolean;
  aiData$ = new Subject<any>();
  aiAPIURL = "";
  controller = new AbortController();
  signal = this.controller.signal;
  constructor(
    private store: Store<RootState>,
    private configService: ConfigService,
    private http: HttpClient
  ) {
  }

  public cancelRequest(id: string) {
    this.stopStreamer = true;
    let headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": localStorage.getItem("token") });
    this.http.post(`${this.aiAPIURL}/request-cancel`, JSON.stringify({id: id}), {
      headers: headers,
      observe: "events",
      responseType: "text",
      reportProgress: true
    }).subscribe({
      next: (v) => {
      },
      error: (err) => {
      }
    });
  }

  async scheduleMeeting(payload: any, callback) {
    await this.streamResponse(`${this.aiAPIURL}/chat`, payload, (text, done) => {
      callback({text, done});
    });
  }

  async textToSpeech(payload: any, callback) {
    await this.streamResponse(`${this.aiAPIURL}/tts`, payload, (text, done) => {
      callback({text, done});
    }, true);
  }


  async callRAG(message: any) {
    await this.streamResponse(`${this.aiAPIURL}/rag`, message, (text, done) => {
      this.aiData$.next({id: message.id, prompt: message.prompt, target: message.target, response: text, status: !done ? "loading" : "finished"});
    });
  }


  async streamResponse(url: string, body: any, callback: any, concatAudio?: boolean) {
    this.stopStreamer = false;
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    try {
        const response = await fetch(url, {
            method: "post",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("token"),
            },
            signal: this.signal,
            body: JSON.stringify(body),
          });
          if (!response.ok || !response.body) {
            callback(response.statusText);
          }
          // Here we start prepping for the streaming response
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let loopRunner = true;
          let text = "";
          let cachedBuffer = "";
          const audioBuffer = [];
          while (loopRunner) {
            // Here we start reading the stream, until its done.
            const { value, done } = await reader.read();
            if (done || this.stopStreamer) {
              loopRunner = false;
            }
            const decodedChunk = decoder.decode(value, { stream: true });
            if (!concatAudio) {
              text += decodedChunk;
              callback(text, done);
            } else {
              // this.logger.info("streamResponse", decodedChunk, decodedChunk.startsWith("START_"), decodedChunk.endsWith("_END"));
              if (decodedChunk.startsWith("START_")) {
                cachedBuffer = decodedChunk.replace("START_", "");
                if (cachedBuffer.endsWith("_END")) {
                  cachedBuffer = cachedBuffer.replace("_END", "");
                  audioBuffer.push(Buffer.from(cachedBuffer, "base64"));
                }
              } else {
                cachedBuffer += decodedChunk.replace("_END", "");
                if (decodedChunk.endsWith("_END")) {
                  audioBuffer.push(Buffer.from(cachedBuffer, "base64"));
                  cachedBuffer = "";
                }
              }

              text = Buffer.concat(audioBuffer).toString("base64");
              if (decodedChunk.endsWith("_END") || done) {
                callback(text, done);
              }
            }
          }
    } catch (err) {
        callback(err);
    }

  }

  cancelStream() {
    this.controller.abort();
  }


  setupAI() {
    this.aiAPIURL = this.configService.get("aiURL");
  }
}
